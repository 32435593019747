export * as configs from './configs'
export { Fruit } from './fruit'

export enum Theme {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark'
}

export enum Roles {
  ROOT = 'root',
  ADMIN = 'admin',
  USER = 'user',
  GUEST = 'guest'
}
