import { createAction, createReducer } from '@reduxjs/toolkit'

import type { User } from '@/types/user'

/**
 * STATE
 */
export const initialState: Partial<User> = {
  PhoneNumber: ''
}

/**
 * ACTION's
 */
export enum ActionTypes {
  SET_PROFILE = 'SET_USER_PROFILE',
  RESET_PROFILE = 'RESET_USER_PROFILE'
}

export class userAct {
  static setProfile = createAction<User, ActionTypes>(ActionTypes.SET_PROFILE)
  static reset = createAction<any, ActionTypes>(ActionTypes.RESET_PROFILE)
}
/**
 * REDUCER's
 */
export default createReducer(initialState, (builder) => {
  return builder
    .addCase(userAct.setProfile, (state, { payload }) => {
      Object.assign(state, payload)
    })
    .addCase(userAct.reset, () => {
      return { PhoneNumber: '' }
    })
})
